/* global document */
import $ from 'jquery';

function updatePlaceholderInMultipleSelect(wrap) {
	const homeSelect = wrap.find('.js-customSelect');
	let defaultPrompt = $(homeSelect).data('defaultPrompt');
	if (typeof defaultPrompt !== 'string') {
		defaultPrompt = 'Select';
	}
	const customSelectInput = wrap.find('.js-custom-select-input');
	const selectedValues = homeSelect.find('option:selected');
	const values = [];
	selectedValues.each((item) => values.push($(selectedValues[item]).text()));
	customSelectInput.attr('placeholder', selectedValues.length === 0 ? defaultPrompt : values.join(', '));
	customSelectInput.val('');
}

function initCustomSelectOptions(e, isMultipleSelect) {
	const optionsDiv = $('<div></div>');
	const notFoundCustomLabel = $(e).data('notFound');
	const notFoundLabel = notFoundCustomLabel !== undefined ? notFoundCustomLabel : 'No result';
	const notFound = $(`<div class="custom-select__option-not-found">${notFoundLabel}</div>`);

	let promptValue = 'false';
	if ($(e).attr('prompt')) {
		promptValue = $(e).attr('prompt') === 'disabled' ? 'true' : '';
	}

	const options = $(e).find('option');
	if (isMultipleSelect) {
		options.sort((a, b) => a.innerHTML.toLowerCase().localeCompare(b.innerHTML.toLowerCase()));
	} else if (!$(e).hasClass('js-customSelect-noSort')) {
		options.sort((a, b) => {
			if ((a.innerHTML.toLowerCase() === 'select' || a.innerHTML.toLowerCase() === 'vyberte') || b.innerHTML.toLowerCase() === 'unfilled') {
				return a;
			}
			if ((b.innerHTML.toLowerCase() === 'select' || b.innerHTML.toLowerCase() === 'vyberte') || a.innerHTML.toLowerCase() === 'unfilled') {
				return b;
			}
			return a.innerHTML.toLowerCase().localeCompare(b.innerHTML.toLowerCase());
		});
	}

	$.each(options, (ix, option) => {
		let oneOption = null;
		const name = `${$(e).attr('data-name')}-${option.value}`;
		if (isMultipleSelect) {
			oneOption = $(`<label for="${name}" class="select-checkbox-wrap select-checkbox-wrap--checkbox js-custom-select-option" data-id="${option.value}" data-disabled="${option.value ? '' : promptValue}"><input class="select-checkbox__input js-select-checkbox-input" type="checkbox" value="${option.value}" id="${name}" data-name="${option.innerHTML}" ${$(option).prop('selected') ? 'checked' : ''} /><span class="select-checkbox__label">${option.innerHTML}</span></label>`);
		} else {
			oneOption = $(`<div class="custom-select__option js-custom-select-option" data-id="${option.value}" data-disabled="${option.value ? '' : promptValue}">${option.innerHTML}</div>`);
		}
		optionsDiv.append(oneOption);
	});
	optionsDiv.append(notFound);

	return optionsDiv.html();
}

function initCustomSelect($el, reinit = false) {
	$el.each((i, e) => {
		const isMultipleSelect = $(e).prop('multiple');
		const disabled = $(e).prop('disabled');
		if ($(e).parent().find('.js-custom-select-input').length === 0) {
			const lightSelect = !$(e).hasClass('input--white');
			const optionsUp = !$(e).hasClass('js-optionsUp') ? '' : ' custom-select__options--up';
			const optionsDiv = $(`<div class="custom-select__options${optionsUp}${isMultipleSelect ? ' custom-select__options--multiple' : ''} js-custom-select-options"></div>`);
			const selectDiv = $(`<input class="custom-select__input input input--select ${lightSelect || 'input--white'}${isMultipleSelect ? ' custom-select__input--multiple' : ''} js-custom-select-input" ${disabled ? 'disabled' : ''} value="${isMultipleSelect ? 'Select' : $(e).find('option:selected').text()}" type="text"><div class="custom-select__arrow"></div>`);

			optionsDiv.html(initCustomSelectOptions(e, isMultipleSelect));
			$(e).parent().prepend(selectDiv, optionsDiv);

			if (isMultipleSelect) {
				updatePlaceholderInMultipleSelect($(e).closest('.input-wrapper'));
			}
		} else if (reinit) {
			const optionsDiv = $(e).parent().find('.js-custom-select-options');
			const selectDiv = $(e).parent().find('.js-custom-select-input');
			optionsDiv.html(initCustomSelectOptions(e, isMultipleSelect));
			selectDiv.prop('disabled', disabled);
			selectDiv.val($(e).find('option:selected').text());
		}
	});
}

function renderBadge(e, containerName) {
	const target = $(e.target);
	const badgeEl = $(`<div class="badge">${target.attr('data-name')}<button id="${target.prop('id')}" class="badge__remove js-badge-remove" aria-label="Remove" type="button"><span class="icon icon-close"></span></button></div>`);
	const badgesWrap = $(`.js-badges-wrap[data-name='${containerName}']`);
	if (badgesWrap) {
		badgesWrap.append(badgeEl);
	}
}

function selectOption(e, id) {
	const homeSelect = $(e.target).closest('.input-wrapper').find('.js-customSelect');
	const homeSelected = id ? homeSelect.find(`[value="${id}"]`) : homeSelect.find('option:selected');
	const promptDisabled = homeSelect.attr('prompt') === 'disabled' ? homeSelected.index() !== 0 : false;
	const customSelectInput = $(e.target).closest('.input-wrapper').find('.js-custom-select-input');
	$('.custom-select__option.is-hidden').removeClass('is-hidden');

	if (customSelectInput.hasClass('custom-select__input--multiple')) {
		updatePlaceholderInMultipleSelect($(e.target).closest('.input-wrapper'));
	} else if (id) {
		homeSelect.val(id).trigger('change');
		customSelectInput.val(homeSelected.text()).trigger('change');
	}

	const inputWrap = $(e.target).closest('.input-wrapper');
	if (inputWrap.length > 0 && inputWrap.hasClass('has-error') && promptDisabled) {
		inputWrap.removeClass('has-error');
		inputWrap.find('.error-container').remove();
	}

	if (`${homeSelected.text()}` !== 'Choose role') {
		const container = $(homeSelect).closest('div .role-add');
		const submit = $(container).find('input[type="submit"]');
		submit.click();
	}
}

function deleteBadge(e) {
	const target = $(e.target);
	const id = (target.attr('id'));
	const item = $(`input[id="${id}"].js-select-checkbox-input`);
	const badge = $(`button[id="${id}"].js-badge-remove`).parent();

	if (item && badge) {
		item.prop('checked', false);
		badge.remove();
	}
}

$(document).on('keyup', '.js-custom-select-input', (e) => {
	const options = $(e.target).parent().find('.js-custom-select-option');
	const value = $(e.target).val().toLowerCase();
	// check if any option contains searching value
	options.each((i, op) => {
		const optionVal = $(op).text().toLowerCase();
		if (optionVal.indexOf(value) !== -1) {
			$(op).removeClass('is-hidden');
		} else {
			$(op).addClass('is-hidden');
		}
	});
	// if enter clicked
	// and the searched option is selected
	// or when not found, go back with last option
	if (e.which === 13 && $(e.target).parent().find('.js-custom-select-option:visible:first').length > 0 && !$(e.target).parent().find('.js-customSelect').prop('multiple')) {
		e.preventDefault();
		$(e.target).data('optionClicked', true);
		if ($(e.target).parent().find('.js-custom-select-option:hover').length > 0) {
			$(e.target).parent().find('.js-custom-select-option:hover').click();
		} else {
			$(e.target).parent().find('.js-custom-select-option:visible:first').click();
		}
		$(e.target).blur();
		$('.custom-select__option.is-hidden').removeClass('is-hidden');
	} else if (e.which === 13) {
		$(e.target).data('optionClicked', true);
		selectOption(e);
		$(e.target).blur();
	}
});

$(document).on('mousedown', '.js-custom-select-option', (e) => {
	e.preventDefault();
});

$(document).on('click', '.js-badge-remove', (e) => {
	const id = $(e.target).attr('id');
	let containerName = id.match(/[-\w]*(skills|sports|types|tags)-/)[0];
	containerName = containerName.slice(0, -1);
	const value = id.slice(containerName.length + 1, id.length);
	const selectedOption = $(`.js-customSelect[data-name='${containerName}'] option[value='${value}']`);
	$(selectedOption).closest('form').attr('data-changed', true);

	selectedOption.removeAttr('selected');
	updatePlaceholderInMultipleSelect($(`.js-customSelect[data-name='${containerName}']`).parent());
	deleteBadge(e);
});

$(document).on('click', '.js-custom-select-option', (e) => {
	if ($(e.target).closest('.js-custom-select-option').attr('data-disabled') !== 'true') {
		const customSelectInput = $(e.target).closest('.input-wrapper').find('.js-custom-select-input');
		const homeSelect = $(e.target).closest('.input-wrapper').find('.js-customSelect');
		const valueId = $(e.target).closest('.js-custom-select-option').attr('data-id');
		const containerName = homeSelect.attr('data-name');
		customSelectInput.data('optionClicked', true);

		if (homeSelect.hasClass('js-select-value-update')) {
			$('.js-select-replace-value').text(valueId);
		}

		const selectedOption = $(`.js-customSelect option[value="${valueId}"]`);

		if (homeSelect.prop('multiple')) {
			if ($(e.target).is(':checked')) {
				selectedOption.attr('selected', true);
				renderBadge(e, containerName);
			} else {
				selectedOption.removeAttr('selected');
				deleteBadge(e);
			}
		}
		selectOption(e, valueId);

		if (homeSelect.prop('multiple')) {
			customSelectInput.focus();
		} else {
			customSelectInput.blur();
		}
	}
});

$(document).on('focus', '.js-custom-select-input', (e) => {
	$(e.target).addClass('custom-select__input--focus');
});

$(document).on('blur', '.js-custom-select-input', (e) => {
	$(e.target).removeClass('custom-select__input--focus');
	if (!$(e.target).data('optionClicked')) {
		selectOption(e);
	}
	$(e.target).removeData('optionClicked');

	const options = $(e.target).parent().find('.js-custom-select-option');
	options.each((i, op) => {
		$(op).removeClass('is-hidden');
	});

	// Autosubmit in case that the select is multiple and the newly focused el is not one of its options
	const select = $(e.target).siblings('.js-customSelect');
	const isMultipleAutoSubmit = typeof $(select).data('autosubmit') !== 'undefined' && $(select).prop('multiple');
	if (isMultipleAutoSubmit) {
		setTimeout(() => {
			const focusTarget = document.activeElement;
			if (!$(focusTarget).hasClass('custom-select__input--multiple')) {
				$(select).closest('form').find('input[type=submit]').click();
			}
		}, 1);
	}
});

$(document).on('reinit', '.js-customSelect', (e) => {
	initCustomSelect($(e.target), true);
});

$.nette.ext('initCustomSelect', {
	init: function initCustomSelectInit() {
		initCustomSelect($('.js-customSelect'));

		const snippets = this.ext('snippets');
		if (!snippets) return;

		snippets.after(($el) => {
			initCustomSelect($el.find('.js-customSelect'));
		});
	},
});
