/* global document */
/* eslint-disable */
import $ from 'jquery';
import Nette from 'nette-forms';
/* eslint-enable */

import './file-input';
import './custom-select';
import './dropdown-select';

Nette.initOnLoad();

Nette.showFormErrors = function showFormErrors(form, errors) {
	const displayed = [];
	let focusElem;

	$(form).find('.has-error').removeClass('has-error');
	$(form).find('.nette-error').remove();

	for (let i = 0; i < errors.length; i += 1) {
		const { element, message } = errors[i];

		let target = $(element).parent();

		if (target.hasClass('custom-checkbox__wrap')) {
			const checkboxWrap = target.closest('.checkbox__wrap');

			if (checkboxWrap.length) {
				target = checkboxWrap;
			}
		}

		const isMulti = element.type === 'radio' || (element.type === 'checkbox' && element.name.substr(-2) === '[]');
		if (isMulti) {
			const checkboxesGroup = target.closest('.checkboxes-group');

			if (checkboxesGroup.length) {
				target = checkboxesGroup;
			}

			target = target.parent();
		}

		if (!displayed.some((error) => error.target === target.get(0) && error.message === message)) {
			const errorContainer = target.children('.error-container.nette-error').last();

			target.addClass('has-error');

			if (errorContainer.length) {
				errorContainer.append('<br />').append(message);
			} else {
				target.append($('<div></div>').addClass('error-container nette-error').text(message));
			}
			displayed.push({ target: target.get(0), message });
		}

		if (!focusElem && element.focus) {
			focusElem = element;
		}
	}

	if (focusElem) {
		// Switch to right tab if possible
		const panel = $(focusElem).closest('div[role="tabpanel"]').get(0);
		if (panel !== null) {
			$(`#${$(panel).attr('id')}-tab`).trigger('click');
		}
		focusElem.focus();
	}
};

$(document).on('change', '.has-error', function errorChanged(e) {
	if (Nette.validateControl(e.target, null, true)) {
		$(this).removeClass('has-error');
		$(this).find('.nette-error').remove();
	}
});

// Allow only numbers in certain inputs
$(document).on('keyup, input', '.numbers-only', function onlyNumbers() {
	if (/\D/g.test(this.value)) {
		this.value = this.value.replace(/\D/g, '');
	}
});

$(document).on('keyup, input', '.phone-prefix-only', function onlyPhonePrefix() {
	const val = this.value;
	if (val.charAt(0) === '+') {
		const rest = val.substring(1);
		if (/\D/g.test(rest)) {
			this.value = `+${rest.replace(/\D/g, '')}`;
		}
	} else if (/\D/g.test(val)) {
		this.value = val.replace(/\D/g, '');
	}
});
$.nette.ext('rightTabCommon', {
	start: function changeStart(jqXHR, settings) {
		const form = settings.nette && settings.nette.form;
		if (form && form.is('.js-imagewrap')) {
			form.siblings('.loading').addClass('is-visible');
		}
	},
	complete: function changeComplete(jqXHR, status, settings) {
		const form = settings.nette && settings.nette.form;
		if (form && form.is('.js-imagewrap')) {
			form.siblings('.loading').removeClass('is-visible');
		}
	},
});
// Validators
function isValidDate(s) {
	const bits = s.split('/');
	const d = new Date(bits[2], bits[1] - 1, bits[0]);
	return !!(d && (d.getMonth() + 1) === parseInt(bits[1], 10) && d.getDate() === Number(bits[0]));
}

function parseDate(s) {
	const bits = s.split('/');
	return new Date(bits[2], bits[1] - 1, bits[0]);
}

function isChild(birthDate) {
	const birthDay = parseDate(birthDate);
	const now = new Date();
	const fifteenYearsAgo = new Date(now.getFullYear() - 15, now.getMonth(), now.getDate());

	return birthDay.valueOf() > fifteenYearsAgo.valueOf();
}

function isValidPhoneNumber(s) {
	return s.replace(/\s+/g, '').match(/^\d+$/) !== null;
}

function isValidPhonePrefix(s) {
	return s.replace(/\s+/g, '').match(/^\+\d+$/) !== null;
}

function isValidUrl(s) {
	const urlPattern = new RegExp('^(https?:\\/\\/)?' // validate protocol
		+ '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
		+ '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
		+ '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
		+ '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
		+ '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
	return !!urlPattern.test(s);
}

Nette.validators.NetvorRoletikTalentsComponentsExtraFormFactory_validateAge = (elem) => (
	isValidDate(elem.value) && isChild(elem.value)
);

Nette.validators.NetvorRoletikTalentsComponentsExtraFormFactory_validatePhoneNumberWithoutPrefix = (elem) => (
	isValidPhoneNumber(elem.value)
);

Nette.validators.NetvorRoletikTalentsComponentsExtraFormFactory_validatePhonePrefix = (elem) => (
	isValidPhonePrefix(elem.value)
);

Nette.validators.NetvorRoletikTalentsComponentsExtraFormFactory_validateUrl = (elem) => (
	isValidUrl(elem.value)
);

$('.js-submit-form').on('submit', function disableSubmit() {
	const submitBtn = $('.js-submit-btn');
	if ($(this).attr('data-state') === 'submitting') {
		return false;
	}
	if (Nette.validateForm($(this)[0])) {
		$(this).attr('data-state', 'submitting');
		submitBtn.addClass('btn--loading');
		return true;
	}
	$(this).attr('data-state', '');
	submitBtn.removeClass('btn--loading');

	return false;
});

function initCustomDataSearch($el) {
	const search = $el.val();
	const target = $el.data('target');
	const link = $el.data('link');
	const url = link.replace('__search__', search);

	if (search === '' || search === undefined) {
		$(target).empty()
			.prop('disabled', true);
		return;
	}

	$.nette.ajax({
		url,
		unique: false,
		off: ['abort', 'unique'],
	})
		.then((data) => {
			const targetValue = $(target).val();
			$(target).empty();
			if (data.length === 0 || search === '') {
				$(target)
					.prop('disabled', true);
				return;
			}

			$(target)
				.prop('disabled', false)
				.append($('<option></option>').text('Choose from questions where this answer was found'));

			let found = false;
			$.each(data, (key, val) => {
				found = found || val === targetValue;
				$(target).append($('<option></option>').attr('value', val).text(val));
			});

			if (found) {
				$(target).val(targetValue);
			}
			$(target).trigger('reinit');
		});
}

$.nette.ext('customDataSearch', {
	init: function customDataSearchInit() {
		$('.custom-data-search-input').each(function initCustomData() {
			initCustomDataSearch($(this));
		});

		const snippets = this.ext('snippets');
		if (!snippets) return;

		snippets.after(($el) => {
			($el).find('.custom-data-search-input').each(function initCustomData() {
				initCustomDataSearch($(this));
			});
		});
	},
});

$(document)
	.on('input', '.custom-data-search-input', function customDataSearchChange() {
		initCustomDataSearch($(this));
	});
