/* eslint-disable */
import $ from 'jquery';

if ($('.jsSearch').length) {
	$(document).on('click', '.jsSearch', (e) => {
		e.preventDefault();
		if ($('.search').hasClass('search--is-open')) {
			$('.search').removeClass('search--is-open');
			$('.jsSearch').removeClass('tabler__btn--active');
		} else {
			$('.search').addClass('search--is-open');
			$('.tabler__btn').addClass('tabler__btn--active');
		}
	});

	$(document).ready(() => {
		if ($('.jsSearchInput').val().length > 0 ) {
			$('.search').addClass('search--is-open');
			$('.tabler__btn').addClass('tabler__btn--active');
		}
	});
}
