/* global document */
const arrowPress = (e, dir, click = false) => {
	const link = document.querySelectorAll(`.js-key-shortcut-${dir}`);
	const block = document.querySelectorAll('.js-key-shortcut-block');
	const focusBlock = document.querySelectorAll('.js-key-shortcut-focus-block');
	if (link.length > 0 && block.length === 0
		&& (focusBlock.l === 0 || !(Array.from(focusBlock)).some((el) => el === document.activeElement))
	) {
		e.preventDefault();
		if (click) {
			link[0].click();
		}
	}
};

document.onkeydown = (e) => {
	if (e.code === 'ArrowUp') {
		arrowPress(e, 'prev');
	}
	if (e.code === 'ArrowDown') {
		arrowPress(e, 'next');
	}
};

document.onkeyup = (e) => {
	if (e.code === 'ArrowUp') {
		arrowPress(e, 'prev', true);
	}
	if (e.code === 'ArrowDown') {
		arrowPress(e, 'next', true);
	}
};
