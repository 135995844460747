/* global document */
function switchVisible(link) {
	const input = link.nextElementSibling;
	if (input.classList.contains('js-promocode-input')) {
		if (input.style.display !== 'none') {
			input.style.display = 'block';
			link.style.display = 'none'; // eslint-disable-line no-param-reassign
		} else {
			link.style.display = 'block'; // eslint-disable-line no-param-reassign
		}
	}
}

document.querySelectorAll('.js-promocode-link').forEach((el) => {
	el.addEventListener('click', () => switchVisible(el));
});
