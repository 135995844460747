/* global document */

/* eslint-disable */
import Cropper from 'cropperjs';
import $ from 'jquery';
/* eslint-enable */

const cropperTitles = {
	en: {
		portraitSmall: 'Thumbnail',
		portraitBig: 'Portrait',
		figure: 'Figure',
		figureVertical: 'Figure (crop for PDF presentation)',
		additionalOne: 'First additional image',
		additionalTwo: 'Second additional image',
		additionalThree: 'Third additional image',
	},
	cs: {
		portraitSmall: 'Náhled',
		portraitBig: 'Portrét',
		figure: 'Postava',
		figureVertical: 'Postava (pro PDF export)',
		additionalOne: 'První dodatečná fotka',
		additionalTwo: 'Druhá dodatečná fotka',
		additionalThree: 'Třetí dodatečná fotka',
	},
};

const aspectRatios = {
	portraitSmall: 100 / 50,
	portraitBig: 446 / 594,
	figure: 420 / 276,
	figureVertical: 446 / 594,
	additionalOne: 420 / 276,
	additionalTwo: 420 / 276,
	additionalThree: 420 / 276,
};

function createCropper(element, type) {
	const cropper = new Cropper(element, {
		aspectRatio: aspectRatios[type] || 1,
		viewMode: 1,
		zoomOnTouch: false,
		zoomOnWheel: false,
		minContainerHeight: 388,
	});
	cropper.cropType = type;
	return cropper;
}

function initCropper(cropper, image, type, secondaryType = null) {
	const secondary = secondaryType !== null;

	const showModal = () => {
		cropper.closest('.modal').modal({
			backdrop: 'static',
		}).modal('show');
	};
	showModal();

	const primaryImage = cropper.find('.js-cropper-tab-content.js-cropper-primary .js-cropper-image');
	const secondaryImage = secondary ? cropper.find('.js-cropper-tab-content.js-cropper-secondary .js-cropper-image') : null;
	primaryImage.on('ready', showModal);

	const lang = $('body').data('lang');
	cropper.find('.js-cropper-title').text(cropperTitles[lang][type] || '');
	cropper.find('.js-cropper-head').toggle(secondary);
	cropper.find('.js-cropper-image').attr('src', image);
	cropper.find('.js-cropper-primary').addClass('active');

	let primaryCropper = createCropper(primaryImage[0], type);
	let secondaryCropper = secondary ? createCropper(secondaryImage[0], secondaryType) : null;

	const changeTab = (e) => {
		e.preventDefault();
		const primary = !secondary || $(e.currentTarget).is('.js-cropper-primary');

		const targetType = primary ? type : secondaryType;
		cropper.find('.js-cropper-primary').toggleClass('active', primary);
		cropper.find('.js-cropper-secondary').toggleClass('active', !primary);

		if (primary && primaryCropper !== null) {
			primaryCropper.destroy();
			primaryCropper = createCropper(primaryImage[0], type);
		}
		if (secondary && secondaryCropper !== null) {
			secondaryCropper.destroy();
			secondaryCropper = secondary ? createCropper(secondaryImage[0], secondaryType) : null;
		}

		cropper.find('.js-cropper-title').text(cropperTitles[targetType] || '');
		cropper.find('[name="type"]').val(targetType);
	};

	const fillFields = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const primaryImageData = primaryCropper.getCroppedCanvas({ fillColor: '#fff' }).toDataURL('image/jpeg');
		const primaryCropType = primaryCropper.cropType;
		const primaryInput = $(`[name="crop_${primaryCropType}"]`);
		const wrap = $(primaryInput).closest('.js-imagewrap');
		const preview = wrap.find('.js-filepreview');

		preview.attr('src', primaryImageData);
		$(primaryInput).val(primaryImageData);

		if (secondaryCropper !== null) {
			const secondaryImageData = secondaryCropper.getCroppedCanvas({ fillColor: '#fff' }).toDataURL('image/jpeg');
			const secondaryCropType = secondaryCropper.cropType;
			$(`[name="crop_${secondaryCropType}"]`).val(secondaryImageData);
		}

		cropper.find('.js-crop-loader').addClass('is-visible');
		cropper.closest('.modal').modal('hide');
		cropper.find('.js-crop-loader').removeClass('is-visible');
	};

	cropper.find('.js-cropper-tab-trigger').on('click', changeTab);
	cropper.find('.js-cropper-submit').on('click', fillFields);

	cropper.closest('.modal').one('hidden.bs.modal', () => {
		cropper.find('.js-cropper-title').text('');
		cropper.find('.js-cropper-head').show();
		cropper.find('.js-cropper-image').attr('src', '');
		cropper.find('.js-cropper-tab-trigger').off('click', changeTab);
		cropper.find('.js-cropper-tab-trigger').removeClass('active');
		cropper.find('.js-cropper-tab-content').removeClass('active');

		primaryCropper.destroy();
		primaryImage.off('ready', showModal);
		if (secondary) {
			secondaryCropper.destroy();
		}

		cropper.find('.js-cropper-submit').off('click', fillFields);
	});
}

$(document).on('click', '.js-cropper', (e) => {
	const { target } = e;
	e.preventDefault();

	// Already uploaded
	const input = $(target).closest('.js-imagewrap').find('input.image-input')[0];
	const image = input.files && input.files[0] ? URL.createObjectURL(input.files[0]) : $(target).data('image');
	initCropper(
		$('.js-cropper-modal').first(),
		image,
		$(target).data('type'),
		$(target).data('typeSecondary') || null,
	);
});
