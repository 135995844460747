/* global document, window */
import './js/globals';

/* eslint-disable */
import $ from 'jquery';
import 'bootstrap';
import 'bootstrap-datepicker';
import 'clockpicker/dist/jquery-clockpicker';
import 'nette.ajax.js';
/* eslint-enable */

import './forms';
import './js/cropper';
import './js/goPay';
import './js/key-shortcut';
import './js/search';
import './js/sideFilter';
import './js/aside-menu';
import './js/simple-lightbox';
import './js/switchVisible';

import './index.scss';

const snippetExtension = $.nette.ext('snippets');
if (snippetExtension) {
	$.nette.ext('snippets', false);
	$.nette.ext('snippets', {
		success(payload) {
			const beforeSnippets = this.ext('beforeSnippets');
			if (beforeSnippets) {
				beforeSnippets.fire(payload);
			}

			if (payload.snippets) {
				this.updateSnippets(payload.snippets);
			}
		},
	}, snippetExtension);
}

$.nette.ext('beforeSnippets', {}, {
	queue: $.Callbacks(),
	add(callback) {
		this.queue.add(callback);
	},
	fire(payload) {
		this.queue.fire(payload);
	},
});

$.nette.ext('snippetLists', {
	init() {
		const beforeSnippets = this.ext('beforeSnippets');
		if (beforeSnippets) {
			beforeSnippets.add((payload) => {
				this.updateSnippetLists(payload.snippetLists);
			});
		}
	},
}, {
	updateSnippetLists(snippetLists) {
		if (!snippetLists) {
			return;
		}

		const usedIds = {};
		const deletions = {};
		Object.keys(snippetLists).forEach((key) => {
			const element = document.getElementById(key);
			if (element) {
				this.updateSnippetList(element, snippetLists[key], usedIds, deletions);
			}
		});
		Object.keys(deletions).forEach((id) => {
			deletions[id].parentNode.removeChild(deletions[id]);
		});
	},
	updateSnippetList(element, childrenIds, usedIdsParam, deletionsParam) {
		const usedIds = usedIdsParam;
		const deletions = deletionsParam;

		let currentNode = element.firstChild;
		let key = 0;

		while (currentNode || key < childrenIds.length) {
			if (currentNode && key < childrenIds.length && currentNode.id === childrenIds[key]) {
				currentNode = currentNode.nextSibling;
				key += 1;
			} else if (key < childrenIds.length) {
				const id = childrenIds[key];

				if (!usedIds[id]) {
					usedIds[id] = true;
					delete deletions[id];

					let child = document.getElementById(id);
					if (!child) {
						child = document.createElement('div');
						child.id = id;
					}

					if (currentNode) {
						element.insertBefore(child, currentNode);
					} else {
						element.appendChild(child);
					}
				}

				key += 1;
			} else {
				const { id } = currentNode;

				if (!id || usedIds[id] || deletions[id]) {
					const tmp = currentNode;
					currentNode = currentNode.nextSibling;
					element.removeChild(tmp);
				} else {
					deletions[id] = currentNode;
					currentNode = currentNode.nextSibling;
				}
			}
		}
	},
});

$.nette.ext('flashes', {
	init() {
		this.fadeOutAlerts($('.alert'));

		const snippets = this.ext('snippets');
		if (snippets) {
			snippets.after(($el) => {
				this.fadeOutAlerts($el.find('.alert'));
			});
		}
	},
}, {
	fadeOutAlerts(alerts) {
		const toFadeOut = alerts.not('[data-fade-out]');
		if (!(toFadeOut.length > 0)) {
			return;
		}

		toFadeOut.attr('data-fade-out', true);
		setTimeout(() => {
			toFadeOut.fadeOut(() => {
				toFadeOut.remove();
			});
		}, 6000);
	},
});

$.nette.ext('rightTabLightbox', {
	init() {
		this.initLightBox($('.js-lightbox'));

		const snippets = this.ext('snippets');
		if (snippets) {
			snippets.after(($el) => {
				this.initLightBox($el.find('.js-lightbox'));
			});
		}
	},
}, {
	initLightBox($el) {
		if ($el.length > 0) {
			$el.simpleLightbox();
		}
	},
});

$.nette.ext('bsModal', {
	init() {
		$('.modal[id^="snippet-"]').each(function bsModalInitShow() {
			if ($(this).find('.modal__content').length) {
				$(this).modal('show');
			}
		});

		$('.modal.open').modal('show');

		$(document).on('hidden.bs.modal', '.modal', function modalHidden() {
			const url = $(this).find('.modal-dialog[data-close-url]').data('closeUrl');
			if (url) {
				$.nette.ajax({
					url,
					off: ['abort', 'unique'],
				});
			}
		});

		const snippets = this.ext('snippets');
		if (snippets) {
			snippets.after(($el) => {
				if ($el.is('.modal')) {
					const display = $el.find('.modal__content').length;
					const modalObject = $el.data('bs.modal');
					if (modalObject) {
						modalObject._isTransitioning = false; // eslint-disable-line no-underscore-dangle
					}
					if (modalObject || display) {
						$el.modal(display ? 'show' : 'hide');
					}
				} else {
					$el.find('.modal.open').modal('show');
				}
			});
		}
	},
});

$.nette.ext('asideClasses', {
	init() {
		this.element = $('#snippet--rightTab');
		this.setAsideClasses();

		const snippets = this.ext('snippets');
		if (snippets) {
			snippets.complete(() => {
				this.setAsideClasses();
			});
		}
	},
}, {
	element: null,
	setAsideClasses() {
		const aside = this.element;
		if (!(aside && aside.length > 0)) {
			return;
		}

		if (aside.hasClass('js-showaside') && !aside.is(':empty')) {
			$('.js-hide-when-aside').attr('hidden', '');
			$('.js-main').addClass('main-content--aside');
			$('body').addClass('rp-noscroll');
		} else if (aside.hasClass('js-showaside')) {
			$('.js-hide-when-aside').removeAttr('hidden');
			$('.js-main').removeClass('main-content--aside');
			$('body').removeClass('rp-noscroll');
		}
	},
});

$.nette.ext('activeExtras', {
	success(payload) {
		if (payload.activeExtraSnippets) {
			Object.keys(payload.activeExtraSnippets).forEach((id) => {
				$(`#${id}`).toggleClass('is-active', !!payload.activeExtraSnippets[id]);
			});
		}
	},
});

function controlRowHeight(target, addOffset) {
	const tr = $(target).closest('tr');
	const height = tr.outerHeight();
	tr.find('.js-check-row-height--result').css('height', addOffset ? 2 * height : height);
}

// change main label by active tab in detail of extra
$(document).on('shown.bs.tab', '.js-tabNavChange a', (e) => {
	const activeTab = $(e.target);
	const otherTabs = $(e.target).siblings();
	const btn = $(e.target).closest('.dropdown').find('.js-toggleNav');
	otherTabs.removeAttr('hidden');
	activeTab.attr('hidden', true);
	btn.text(activeTab.text());
});

$(document).on('keydown', '.force-numeric', (e) => (e.keyCode >= 47 && e.keyCode <= 57)
	|| (e.keyCode >= 96 && e.keyCode <= 105)
	|| $.inArray(e.keyCode, [190, 173, 109, 8, 46, 37, 39, 189, 9]) !== -1);

$(() => {
	$.nette.init();

	$(document).on('click', 'a[data-ajax-url]', function ajaxLinkedClicked(e) {
		$.nette.ajax($(this).data('ajaxUrl'), this, e);
	});

	let toggleNavTimeout;

	$(document).on('click', '.js-toggleNav', (e) => {
		const $this = $(e.target);
		if (!$('.js-toggleNav.is-open').is($this)) {
			$('.js-toggleNav.is-open').removeClass('is-open');
		}
		if (!$this.hasClass('is-open')) {
			clearTimeout(toggleNavTimeout);
			$this.addClass('is-open');
		} else {
			$this.removeClass('is-open');
		}
	});
	$(document).on('mouseleave', '.dropdown__content, .js-toggleNav', (e) => {
		const wrap = $(e.target).closest('.dropdown');
		clearTimeout(toggleNavTimeout);
		toggleNavTimeout = setTimeout(() => {
			if (!wrap.find('.dropdown__content').is(':hover') && wrap.find('.secondary-on').length === 0) {
				wrap.find('.js-toggleNav').removeClass('is-open');
			}
		}, 500);
	});
	$(document).on('click', '.dropdown__content a', (e) => {
		const wrap = $(e.target).closest('.dropdown');
		clearTimeout(toggleNavTimeout);
		wrap.find('.js-toggleNav').removeClass('is-open');
	});

	$(document).on('mouseenter', '.js-secondaryToggledNav', (e) => {
		$('.secondary-toggled--active').remove();
		const navContent = $(e.target).next('.secondary-toggled__nav');
		let positionX = $(e.target).offset().left;
		let positionY = $(e.target).offset().top;

		let onLeftTransform = -100;

		if ((positionX + $(e.target).outerWidth() + navContent.outerWidth() + 10) < $(window).outerWidth()) {
			positionX += $(e.target).outerWidth();
			onLeftTransform = 0;
		}

		if (positionY + navContent.outerHeight() + 10 > $('body').outerHeight()) {
			positionY -= navContent.outerHeight() - $(e.target).outerHeight();
		}

		const finalNav = navContent.clone().addClass('secondary-toggled--active').css({
			top: `${positionY}px`,
			left: `${positionX}px`,
			transform: `translateX(${onLeftTransform}%)`,
		});

		finalNav.find('a.ajax').on('click', function linkClicked(clickEvent) {
			$.nette.ajax({}, this, clickEvent);
			finalNav.remove();
		});

		navContent.addClass('secondary-on');
		$('body').append(finalNav);
	});
	$(document).on('mouseleave', '.js-secondaryToggledNav, .secondary-toggled--active', () => {
		if (!$('.secondary-toggled--active').is(':hover')) {
			$('.secondary-toggled--active').remove();
			const secondaryOriginal = $('.secondary-on');
			secondaryOriginal.removeClass('secondary-on');

			const wrap = secondaryOriginal.closest('.dropdown');
			const originalNavDivs = wrap.find('.dropdown__content');
			if (originalNavDivs.length > 0 && !originalNavDivs.is(':hover')) {
				wrap.find('.js-toggleNav').removeClass('is-open');
			}
		}
	});
	$(document).on('click', '.secondary-toggled__nav a', () => {
		$('.secondary-toggled--active').remove();
	});

	$(document).on('change', '[data-autosubmit]', function autosubmit() {
		$(this).closest('form').find('input[type=submit]').click();
	});
	$(document).on('mouseenter', '[data-tooltip]', (e) => {
		$('body').find('.tooltip-result').remove();
		const tooltipDiv = $(e.target).closest('[data-tooltip]');
		const text = $(e.target).data('tooltip') || tooltipDiv.data('tooltip');
		if (text && text !== '') {
			const replaced = text.replace(/\n/g, '&lt;br/&gt;');
			const textHTML = $.parseHTML(replaced);
			const positionX = tooltipDiv.offset().left + ($(e.target).outerWidth() / 2);
			const positionY = tooltipDiv.offset().top - 10;
			const halfOfTooltipMaxWidth = 110;
			let positionClass = '';
			if (positionX < halfOfTooltipMaxWidth) {
				positionClass = ' tooltip-result--right';
			} else if (positionX > ($(window).outerWidth() - halfOfTooltipMaxWidth)) {
				positionClass = ' tooltip-result--left';
			}
			if (textHTML[0]) {
				const tooltip = `<div class="tooltip-result${positionClass}" style="top: ${positionY}px; left: ${positionX}px">${textHTML[0].data}</div>`;
				$('body').append(tooltip);
			}
		}
	});
	$(document).on('mouseleave', '[data-tooltip]', () => {
		$('body').find('.tooltip-result').remove();
	});
	$(document).on('click', '[data-tooltip][data-ajax-pass]', (e) => {
		e.preventDefault();
		$('body').find('.tooltip-result').remove();
	});
});

$(document).on('click', '.js-check-row-height', (e) => {
	controlRowHeight($(e.target), true);
});
$(window).on('load', () => {
	$('.js-check-row-height--result').each((i, v) => {
		controlRowHeight(v, false);
	});
});

$(document).ready(() => {
	$('.payment-invoke')
		.on('click', function createPayment() {
			let link = $(this).data('link');
			const promo = $(this).hasClass('payment-invoke-profile') ? $('.js-promocode-profile') : $('.js-promocode');
			if (promo !== null) {
				const code = promo.val();
				if (code.length > 0) {
					link += `&code=${code}`;
				}
			}

			$.nette.ajax(
				link,
			).done((response) => {
				const arr = $.parseJSON(response);

				// eslint-disable-next-line no-undef
				_gopay.checkout({
					gatewayUrl: arr.url,
					inline: true,
				});
			});
		});
});

$(document).on('click', '.nav-toggle', (e) => {
	e.preventDefault();
	$('.nav-toggle').toggleClass('nav-toggle--nav-is-open');
	$('.nav-collapse').toggleClass('nav-collapse--nav-is-open');
	$('.header').toggleClass('header--nav-is-open');
});

$(document).on('click', '.js-drAside-filters', (e) => {
	e.preventDefault();
	$('.filter').toggleClass('filter--is-open');
});

// On load save form current state
const form = $('#account-form');
const	original = form.serialize();

form.submit(() => { window.onbeforeunload = null; });

window.onbeforeunload = function () {
	if (form.serialize() !== original) {
		return 'Are you sure you want to leave?';
	}

	return null;
};
