/* global document */
import $ from 'jquery';

function initDropdownSelectOptions(e) {
	const optionsDiv = $('<div></div>');

	let promptValue = 'false';
	if ($(e).attr('prompt')) {
		promptValue = $(e).attr('prompt') === 'disabled' ? 'true' : '';
	}

	const options = $(e).find('option');

	options.sort((a, b) => {
		if ((a.innerHTML.toLowerCase() === 'select' || a.innerHTML.toLowerCase() === 'vyberte') || b.innerHTML.toLowerCase() === 'unfilled') {
			return a;
		}
		if ((b.innerHTML.toLowerCase() === 'select' || b.innerHTML.toLowerCase() === 'vyberte') || a.innerHTML.toLowerCase() === 'unfilled') {
			return b;
		}
		return a.innerHTML.toLowerCase().localeCompare(b.innerHTML.toLowerCase());
	});

	$.each(options, (ix, option) => {
		let oneOption = null;
		oneOption = $(`<div class="dropdown__item js-dropdown-select-option" data-id="${option.value}" data-disabled="${option.value ? '' : promptValue}">${option.innerHTML}</div>`);
		optionsDiv.append(oneOption);
	});

	return optionsDiv.html();
}

function initDropdownSelect($el, reinit = false) {
	$el.each((i, e) => {
		const disabled = $(e).prop('disabled');
		const label = $(e).data('label');
		if ($(e).find('.js-dropdown-select-input').length === 0) {
			const optionsUp = !$(e).hasClass('js-optionsUp') ? '' : ' dropdown__content--up';
			const optionsDiv = $(`<div class="dropdown__content dropdown__content${optionsUp}${''} js-dropdown-select-options"></div>`);
			const dropdownSelected = $(`<strong class="select js-dropdown-select-input"">${$(e).find('option:selected').text()}</strong>`);
			optionsDiv.html(initDropdownSelectOptions(e));
			const dropdownLabel = $(`<label class="dropdown__label">${label}<sup class="text-danger label__sup">*</sup></label>`);
			const dropdownIcon = $('<span class="icon icon-chevron-down"></span>');
			const dropdownButton = $('<button type="button" class="btn btn--smaller btn--secondary btn--dropdown btn--dropdown-select js-toggleNav jsDropdownSelect"></button>').append(dropdownLabel, dropdownSelected, dropdownIcon);
			const dropdown = $('<div class="dropdown"></div>').append(dropdownButton, optionsDiv);
			$(e).closest('.input-wrapper').prepend(dropdown);
		} else if (reinit) {
			const optionsDiv = $(e).parent().find('.js-dropdown-select-options');
			const dropdownSelected = $(e).parent().find('.js-dropdown-select-input');
			optionsDiv.html(initDropdownSelectOptions(e));
			dropdownSelected.prop('disabled', disabled);
			dropdownSelected.val($(e).find('option:selected').text());
		}
	});
}

function selectOption(e, id) {
	$(this).addClass('dropdown__item--active');
	const homeSelect = $(e.target).closest('.input-wrapper').find('.js-dropdownSelect');
	const homeSelected = id ? homeSelect.find(`[value="${id}"]`) : homeSelect.find('option:selected');
	const promptDisabled = homeSelect.attr('prompt') === 'disabled' ? homeSelected.index() !== 0 : false;
	const customSelectInput = $(e.target).closest('.input-wrapper').find('.js-dropdown-select-input');
	$('.dropdown__item.is-hidden').removeClass('is-hidden');

	homeSelect.val(id).trigger('change');
	customSelectInput.text(homeSelected.text()).trigger('change');

	const inputWrap = $(e.target).closest('.input-wrapper');
	if (inputWrap.length > 0 && inputWrap.hasClass('has-error') && promptDisabled) {
		inputWrap.removeClass('has-error');
		inputWrap.find('.error-container').remove();
	}
}

$(document).on('keyup', '.js-dropdown-select-input', (e) => {
	const options = $(e.target).parent().find('.js-dropdown-select-option');
	const value = $(e.target).val().toLowerCase();
	// check if any option contains searching value
	options.each((i, op) => {
		const optionVal = $(op).text().toLowerCase();
		if (optionVal.indexOf(value) !== -1) {
			$(op).removeClass('is-hidden');
		} else {
			$(op).addClass('is-hidden');
		}
	});
	// if enter clicked
	// and the searched option is selected
	// or when not found, go back with last option
	if (e.which === 13 && $(e.target).parent().find('.js-dropdown-select-option:visible:first').length > 0 && !$(e.target).parent().find('.js-dropdownSelect').prop('multiple')) {
		e.preventDefault();
		$(e.target).data('optionClicked', true);
		if ($(e.target).parent().find('.js-dropdown-select-option:hover').length > 0) {
			$(e.target).parent().find('.js-dropdown-select-option:hover').click();
		} else {
			$(e.target).parent().find('.js-dropdown-select-option:visible:first').click();
		}
		$(e.target).blur();
		$('.dropdown__item.is-hidden').removeClass('is-hidden');
	} else if (e.which === 13) {
		$(e.target).data('optionClicked', true);
		selectOption(e);
		$(e.target).blur();
	}
});

$(document).on('mousedown', '.js-dropdown-select-option', (e) => {
	e.preventDefault();
});

$(document).on('click', '.js-dropdown-select-option', (e) => {
	if ($(e.target).closest('.js-dropdown-select-option').attr('data-disabled') !== 'true') {
		const customSelectInput = $(e.target).closest('.input-wrapper').find('.js-dropdown-select-input');
		const homeSelect = $(e.target).closest('.input-wrapper').find('.js-dropdownSelect');
		const valueId = $(e.target).closest('.js-dropdown-select-option').attr('data-id');
		customSelectInput.data('optionClicked', true);
		const dropDownButton = $(e.target).closest('.input-wrapper').find('.jsDropdownSelect');

		if (homeSelect.hasClass('js-select-value-update')) {
			$('.js-select-replace-value').text(valueId);
		}
		selectOption(e, valueId);

		dropDownButton.blur();
	}
});

$(document).on('focus', '.js-dropdown-select-input', (e) => {
	$(e.target).addClass('custom-select__input--focus');
});

$(document).on('blur', '.js-dropdown-select-input', (e) => {
	$(e.target).removeClass('custom-select__input--focus');
	if (!$(e.target).data('optionClicked')) {
		selectOption(e);
	}
	$(e.target).removeData('optionClicked');

	const options = $(e.target).parent().find('.js-dropdown-select-option');
	options.each((i, op) => {
		$(op).removeClass('is-hidden');
	});
});

$(document).on('reinit', '.js-dropdownSelect', (e) => {
	initDropdownSelect($(e.target), true);
});

$.nette.ext('initDropdownSelect', {
	init: function initDropdownSelectInit() {
		initDropdownSelect($('.js-dropdownSelect'));

		const snippets = this.ext('snippets');
		if (!snippets) return;

		snippets.after(($el) => {
			initDropdownSelect($el.find('.js-dropdownSelect'));
		});
	},
});
