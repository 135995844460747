/* global document */

import $ from 'jquery';

function setMinimizeSideFilterCookie(event) {
	const { currentTarget } = event;
	const cookieName = 'roletik-cookies-minimize-side-filter=';
	const { asideType } = currentTarget.dataset;

	const expires = new Date();
	const expirationTimeAfterOneYear = 365 * 24 * 60 * 60 * 1000; // days * hours * minutes * seconds * milliseconds
	expires.setTime(
		expires.getTime() + expirationTimeAfterOneYear,
	);

	if (document.cookie.indexOf(cookieName) === -1 && asideType === 'min') {
		document.cookie = `${cookieName}1;path=/;expires=${expires.toUTCString()}`;
	} else {
		document.cookie = `${cookieName};path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
	}
}

$.nette.ext('minimizeCookies', {
	init: function minimizeCookiesInit() {
		let asideTriggers = document.querySelectorAll('.js-aside-trigger');
		asideTriggers.forEach((asideTrigger) => {
			asideTrigger.addEventListener('click', setMinimizeSideFilterCookie);
		});

		const snippets = this.ext('snippets');
		if (!snippets) return;
		snippets.complete(() => {
			asideTriggers = document.querySelectorAll('.js-aside-trigger');
			asideTriggers.forEach((asideTrigger) => {
				asideTrigger.addEventListener('click', setMinimizeSideFilterCookie);
			});
		});
	},
});
