/* global document, window */
/* eslint-disable */
import $ from 'jquery';
/* eslint-enable */

let totalSize = 0;

function getOrientation(data) {
	const view = new DataView(data);
	if (view.getUint16(0, false) !== 0xFFD8) {
		return -2;
	}

	const length = view.byteLength;
	let offset = 2;
	while (offset < length) {
		if (view.getUint16(offset + 2, false) <= 8) {
			return -1;
		}

		const marker = view.getUint16(offset, false);
		offset += 2;
		if (marker === 0xFFE1) {
			offset += 2;
			if (view.getUint32(offset, false) !== 0x45786966) {
				return -1;
			}

			const little = view.getUint16(offset += 6, false) === 0x4949;
			offset += view.getUint32(offset + 4, little);

			const tags = view.getUint16(offset, little);
			offset += 2;
			for (let i = 0; i < tags; i += 1) {
				if (view.getUint16(offset + (i * 12), little) === 0x0112) {
					return view.getUint16(offset + (i * 12) + 8, little);
				}
			}
		} else if ((marker & 0xFF00) !== 0xFF00) { // eslint-disable-line no-bitwise
			break;
		} else {
			offset += view.getUint16(offset, false);
		}
	}

	return -1;
}

function readURL(input) {
	const wrap = $(input).closest('.js-imagewrap');
	const preview = wrap.find('.js-filepreview');
	const name = wrap.find('.file-big__pseudo-btn').length > 0 ? wrap.find('.file-big__pseudo-btn') : wrap.find('.file-line__pseudo-btn');
	const deleteBtn = wrap.find('.js-filedelete');
	const cropBtn = wrap.find('.js-cropper');
	wrap.find('.js-updateclear').val('');
	wrap.find('.js-currentfile').val('');

	if (preview.length > 0) {
		preview.css({
			width: '',
			height: '',
		});

		if (input.files && input.files[0]) {
			const size = ((input.files[0].size / 1024) / 1024).toFixed(4);
			if (size < 4) {
				totalSize += parseInt(size, 10);
				wrap.find('.loading').addClass('is-visible');
				const reader = new window.FileReader();
				reader.onload = (e) => {
					const dataUrl = e.target.result;
					reader.onload = (e2) => {
						const orientation = getOrientation(e2.target.result);
						const rotation = [0, 0, 0, 180, 180, 270, 90, 90, 270][orientation] || 0;
						const flip = [false, false, true, false, true, true, false, true, false][orientation] || false;
						const dimSwitch = rotation === 90 || rotation === 270;

						const width = preview.width();
						const height = preview.height();

						preview.css({
							transform: `${dimSwitch ? `translate(${(width - height) / 2}px, ${(height - width) / 2}px)` : ''}rotate(${rotation}deg) ${flip ? ' scaleX(-1)' : ''}`,
							width: dimSwitch ? height : '',
							height: dimSwitch ? width : '',
						});

						preview.removeAttr('src');
						setTimeout(() => {
							preview.attr('src', dataUrl);
							cropBtn.click();
						}, 10);

						if (name.attr('data-default')) {
							name.text(input.files[0].name);
						}
						wrap.find('.loading').removeClass('is-visible');
						wrap.addClass('has-file');
					};
					reader.readAsArrayBuffer(input.files[0]);
				};
				reader.readAsDataURL(input.files[0]);

				if ($('.js-file-input-big').length > 0) {
					if (totalSize >= 5) {
						$('.js-file-input-big').removeAttr('hidden');
					} else {
						$('.js-file-input-big').attr('hidden', true);
					}
				}
				deleteBtn.removeAttr('hidden');
				cropBtn.removeAttr('hidden');
			} else {
				wrap.find('.error-container').remove();
				wrap.addClass('has-error');
				wrap.append(`<div class="error-container nette-error">${input.getAttribute('data-max-error')}</div>`);
			}
		} else if (preview.attr('src').length > 0) {
			preview.removeAttr('src');
			if (name.attr('data-default')) {
				name.text(name.attr('data-default'));
			}
			wrap.removeClass('has-file');
			deleteBtn.attr('hidden', true);
			cropBtn.attr('hidden', true);
		}
	}

	if ($('.js-file-input-missing').length > 0) {
		setTimeout(() => {
			let isMissing = false;
			$('.js-imagewrap.is-required:not(.has-file)').each(() => {
				isMissing = true;
			});

			if (!isMissing) {
				$('.js-file-input-missing').attr('hidden', true);
			} else {
				$('.js-file-input-missing').removeAttr('hidden');
			}
		}, 1000);
	}
}

$(document).on('change', 'input.image-input', (e) => {
	readURL(e.target);
});

$(document).on('click', '.js-pseudoinput, .js-focusfileinp', (e) => {
	$(e.target).closest('.js-imagewrap').find('.image-input').click();
});

$(document).on('click', '.js-filedelete', (e) => {
	const input = $(e.target).closest('.js-imagewrap').find('input.image-input');
	input.val('');
	readURL(input);
	$(e.target).parent().removeClass('file-line__btns--visible');
	$(e.target).parent().siblings('.js-pseudoinput').removeClass('file-line__pseudo-btn--hidden');
});
